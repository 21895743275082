import React, { useEffect, useRef } from 'react';

const TradingViewWidget = () => {
  const tradingViewWidgetRef = useRef();

  useEffect(() => {
    // Dynamically load the TradingView script
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-screener.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: 490,
      defaultColumn: 'moving_averages',
      screener_type: 'crypto_mkt',
      displayCurrency: 'USD',
      colorTheme: 'light',
      locale: 'in'
    });

    // Append the script to mainRef's current element
    if (tradingViewWidgetRef.current) {
        tradingViewWidgetRef.current.appendChild(script);
    }
    return () => {
      if (tradingViewWidgetRef.current) {
        tradingViewWidgetRef.current.removeChild(script);
      }
    };

  }, []);

  return (
    <>
      <div className="tradingview-widget-container"  style={{maxWidth: 1400, margin:"auto"}}>
      <div className="tradingview-widget-copyright">
         <h4 className="black-text" >Track All Crypto</h4>
     </div>
        <div className="tradingview-widget-container__widget"  ref={tradingViewWidgetRef}></div>
       
      </div>
    </>
  );
};

export default TradingViewWidget;
